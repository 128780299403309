/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
@import 'functions';
@import 'variables';

:root, :host {
  --#{$fa-css-prefix}-style-family-sharp: 'Font Awesome 6 Sharp';
  --#{$fa-css-prefix}-font-sharp-light: normal 300 1em/1 'Font Awesome 6 Sharp';
}

@font-face {
  font-family: 'Font Awesome 6 Sharp';
  font-style: normal;
  font-weight: 300;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-sharp-light-300.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-sharp-light-300.ttf') format('truetype');
}

.fasl,
.#{$fa-css-prefix}-light {
  font-weight: 300;
}
